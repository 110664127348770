(function(){
	// var forms = document.forms['favori-form'];
	var forms = document.querySelectorAll('form[name="favori-form"]');

	//~-~-~-~-~-~-~-~-~-~-~-~-~-~-~-~-~-~-~-~-~-~-~-~-~-~-~
	// Ajax de recherche sur carte
	function ajaxChangeFavori ( datas, button, isMonCompte ) {
		var request = new XMLHttpRequest();
		request.open('POST', favoriAjax.ajaxurl + "?action=favori&security=" + favoriAjax.security + "&datas=" + JSON.stringify(datas), true);
		request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');

		request.onload = function() {
			if (200 === this.status) {
				if ( this.response > 0 ) {
					switchButton( button, isMonCompte );
				}
			} else {
				console.log('fail | status : ' + this.status + ' | response : ' + this.response);
			}
		};

		request.onerror = function() {
			console.log('connection error');
		};

		request.send();
	}

	function switchButton( button, isMonCompte ) {
		if ( button.value === 'ajout_favori') {
			button.value = 'enleve_favori';
			button.classList.remove("ico-heart");
			button.classList.add("ico-heart-full");
			button.title = 'Enlever des favoris';
		} else {
			button.value = 'ajout_favori';
			button.classList.remove("ico-heart-full");
			button.classList.add("ico-heart");
			button.title = 'Ajouter en favori';
			if ( isMonCompte ) {
				var section = button.closest('.bloc-favori-js');
				var h3 = document.getElementById('nb_favori');
				var spanH3 = h3.querySelector('span');
				var nbFav = parseInt(h3.dataset.nbfavori);
				section.remove();
				nbFav--;
				h3.dataset.nbfavori = nbFav;
				if ( nbFav > 1 ) {
					spanH3.innerHTML = nbFav + ' annonces'
				} else {
					spanH3.innerHTML = nbFav + ' annonce'
				}
			}
		}
		button.blur();
	}

	if (NodeList.prototype.isPrototypeOf(forms)) {
		forms.forEach(
			function(currentValue) {
				currentValue.addEventListener('submit', function(e) {
					e.preventDefault();

					var formDatas = new FormData(currentValue);
					var post_id = formDatas.get('post_id');
					var button = currentValue.querySelector('button');
					datas = {
						'post_id' : post_id,
						'_wp_http_referer' : formDatas.get('_wp_http_referer')
					};
					datas['wpn_coup_de_coeur'+post_id] = formDatas.get('wpn_coup_de_coeur'+post_id);
					datas['from'] = button.value;

					var isMonCompte = currentValue.dataset.moncompte === 'true';

					ajaxChangeFavori( datas, button, isMonCompte );
				});

			}
		);
	}

})();
